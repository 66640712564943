import React from "react";
import ReactPlayer from "react-player";
import Image from "../assets/Rectangle 13.png";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import CardSection from "./CardSection";

const Home = () => {
    return (
        <div>

            <div className="background-header pb-[] ">
                <div className="secondHeader ">
                    <div className="secondHeader pb-[100px]">
                        <Header />
                        <div className="relative mt-[50px] px-[20px] md:px-[0px] flex flex-col items-center text-center">
                            <div className="max-w-[1100px] w-full">
                                <div className="text-white text-[12vw] md:text-[9vw] lg:text-[8vw] xl:text-[6vw] leading-tight font-normal font-roboto">
                                    <span className="font-extrabold bg-gradient-to-r from-[#FDB04F] to-[#FE4E9F] bg-clip-text text-transparent">
                                    SolSentry
                                    </span>
                                </div>

                                <div className="text-white text-[6vw] md:text-[5vw] lg:text-[4vw] xl:text-[3vw] leading-tight font-normal font-roboto">
                                    <span className="font-extrabold  bg-clip-text ">
                                    Leading the automated trading revolution on Solana.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="text-light  px-[20px] text-center mt-[10px]  text-[17px] leading-[20px] text-white ">
                            Lock in your {" "}
                            <span className="font-bold"> gains.</span> &nbsp;
                            Limit your {" "}
                            <span className="font-bold"> losses.</span> &nbsp;
                            Dominate the {" "}
                            <span className="font-bold"> market.</span>
                        </div>
                        <a href="https://t.me/sol_sentry_beta_bot">
                        <div className="colorClass  text-center mt-[20px] p-[10px] max-w-[142px] mx-auto text-white text-[17px] leading-[17px] font-bold fontClass rounded-[8px]">
                            Try The Beta
                        </div></a>

                    </div>
                </div>
            </div>

            <CardSection />

            <div className="card-main py-[20xp]  relative">
                <div className="absolute right-0">
                    <img src={require("../assets/Ellipse 1.png")} alt="" />
                </div>
                <div className=" text-center mx-auto  text-[26px] py-[20px]  md:text-[36px]  leading-[30px] md:leading-[42px] fontClass2 videClla">
                    Want to learn more about <br /> SolSentry's MVP BETA and its unique trading style?
                </div>
                <div className="max-w-[813px]  relative w-full z-100 mt-[20px]  mx-auto h-[438px] ">
                    <div className="mx-auto z-10 w-full">
                        <ReactPlayer
                            url="https://youtu.be/4rPXLG0tNnU"
                            thumbnail={Image}
                            className="z-1000 mx-auto"
                            width="90%"
                        />
                    </div>
                </div>


            </div>



            <div className="animated-background"></div>

            <Footer />
        </div>
    );
};

export default Home;
