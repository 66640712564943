import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";

const Order = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currencies = [
    {
      value: "USD",
      label: "5px",
    },
    {
      value: "EUR",
      label: "5px",
    },
    {
      value: "BTC",
      label: "฿",
    },
    {
      value: "JPY",
      label: "¥",
    },
  ];
  return (
    <div className="order  py-[20px]  px-[20px] md:px-[50px]  max-w-[1280px] mx-auto ">
      <div className="text-[40px] leading-[60px] text-white text-start font-bold fontClass pb-[10px] border-b-[2px] border-b-[white]">
        Orders
      </div>

      <div className=" ">
        <Modal show={show} onHide={handleClose} className=" custom-modal    ">
          <Modal.Body className=" bg-[#020204] rounded-[8px]  ">
            <div className="flex justify-center text-white items-center gap-[50px] w-full">
              <div className="flex gap-[10px] justify-center items-center ">
                <div>
                  <img src={require("../assets/Vector.png")} alt="" />
                </div>
                <div className="text-[12px] leading-[18px] fontClass2 text-white">
                  13213123123
                </div>
              </div>
              <div className="flex gap-[20px]">
                <div className="background-header1 p-[10px]  flex justify-center items-center   text-[#FE509E] text-[8px] leading-[12px] fontClass2 ">
                  HALF
                </div>
                <div className="background-header1 text-[#FE509E] text-[8px] flex justify-center items-center leading-[12px] fontClass2 ">
                  MAX
                </div>
              </div>
            </div>

            <div className=" mt-[50px] flex flex-col   md:flex-row  justify-center md:justify-between items-center ">
              <div>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Amount Purchasing"
                  defaultValue="EUR"
                  className="w-[242px] h-[63px]"
                  InputProps={{
                    style: {
                      color: "WHITE",
                      background: "black",
                      margin: "5px",
                    },
                    sx: { "& fieldset": { borderColor: "#FE8077" } },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={require("../assets/Group 18.png")} alt="" />{" "}
                        {/* Custom icon */}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "#FE509E" }, // Change color of the label
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div  className="mt-[20px]  md:mt-[0px]">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Priority Fees"
                  defaultValue="EUR"
                  className="w-[242px] h-[63px]"
                  InputProps={{
                    style: {
                      color: "WHITE",
                      background: "black",
                      margin: "5px",
                    },
                    sx: { "& fieldset": { borderColor: "#FE8077" } },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={require("../assets/Group 18.png")} alt="" />{" "}
                        {/* Custom icon */}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "#FE509E" }, // Change color of the label
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="mt-[20px] md:mt-[50px] flex flex-col   md:flex-row  justify-center md:justify-between items-center ">
              <div>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Trigger Percent"
                  defaultValue="EUR"
                  className="w-[242px] h-[63px]"
                  InputProps={{
                    style: {
                      color: "WHITE",
                      background: "black",
                      margin: "5px",
                    },
                    sx: { "& fieldset": { borderColor: "#FE8077" } },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={require("../assets/Group 18.png")} alt="" />{" "}
                        {/* Custom icon */}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "#FE509E" }, // Change color of the label
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div  className="mt-[20px]  md:mt-[0px]">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Slippage"
                  defaultValue="EUR"
                  className="w-[242px] h-[63px] border-[1px]    border-white"
                  InputProps={{
                    style: {
                      color: "WHITE",
                      background: "black",
                      border: "1px #FE509E solid",
                      margin: "5px",
                    },
                    sx: { "& fieldset": { borderColor: "#FE8077" } },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={require("../assets/Group 18.png")} alt="" />{" "}
                        {/* Custom icon */}
                      </InputAdornment>
                    ),

                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "#FE509E" }, // Change color of the label
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="flex    justify-center items-center   md:justify-start items-start   mt-[20px] gap-[20px]">
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label
                for="vehicle1"
                className="text-[white] text-[12px] leading-[18px] fontClass2"
              >
                {" "}
                Auto Double Slippage
              </label>
            </div>

            <div
              className=" mt-[50px] flex justify-center items-center cursor-pointer  "
              onClick={handleClose}
            >
              <img src={require("../assets/Group 28.png")} alt="" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {[1, 2, 3].map((item) => {
        return (
          <div className="flex justify-between items-center mt-[20px]">
            <div className="flex  gap-[10px] md:gap-[100px]">
              <div className="videClla  text-[12px] md:text-[16px] leading-[20px] md:leading-[26px] font-semibold fontClass ">
                14:24 $WIF
              </div>
              <div className="text-white text-[12px] md:text-[16px] leading-[20px] md:leading-[26px] font-normal fontClass">
                +0.0005 SOL (+2.1%) at Peak Price
              </div>
            </div>
            <div className="flex gap-[10px]">
              <div
                onClick={handleShow}
                className=" cursor-pointer  colorClass p-[5px] text-white text-[12px] md:text-[17px] leading-[17px] font-bold fontClass rounded-[8px]"
              >
                View Details
              </div>
              <div className="colorClass bg-cell  bg-cell py-[5px] px-[10px]  text-white text-[12px] md:text-[12px] leading-[17px] font-bold fontClass rounded-[8px]">
                Sell Now
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Order;
