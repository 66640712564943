import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Order from "./Order";
import Footer from "../Footer/Footer";
import InputSection from "./InputSection";
import Footer2 from "../Footer/Footer2";
import Header2 from "../Header/Header2";
import Notification from "./Notification";

const Wallet = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);
  return (
    <>
      {show ? (
        <div class="loader"></div>
      ) : (
        <div>
          <div className="secondHeader1">
            <div className="secondHeader  pb-[50px] ">
              <div className="flex  pt-[20px] px-[50px] justify-between items-center">
                <div>
                  <img src={require("../assets/image 1.png")} alt="" />
                </div>
                <div className="cursor-pointer" onClick={() => setShow(!show)}>
                  <img
                    src={require("../assets/fluent_alert-20-filled.png")}
                    alt=""
                  />
                </div>
              </div>

              {show ? (
                <div className="absolute right-[50px] z-10">
                  {" "}
                  <Notification />{" "}
                </div>
              ) : (
                ""
              )}

              <div className="px-[20px] mt-[50px]">
                <InputSection />
              </div>
            </div>
          </div>

          <div className="card-main py-[50px]">
            <Order />
          </div>
          <Footer2 />
        </div>
      )}
    </>
  );
};

export default Wallet;
