import React from "react";

const CardSection = () => {
  const data = [
    {
      name: "Live Now: MVP BETA",
      img: require("../assets/simple-icons_blockchaindotcom.png"),
      text: "SolSentry watches token prices 24/7.  When the token price dips, SolSentry protects you gains and automatically sells.",
    },
    {
      name: "Phase II - Automated Trading",
      img: require("../assets/token_swap.png"),
      text: "Set buy and sell triggers based on conditions like changes in volume, changes in price, large orders, or changes in liquidity.",
    },
    {
      name: "Phase III - dApp and Platform",
      img: require("../assets/icon-park-outline_api-app.png"),
      text: "Build and test advanced trading strategies. Connect with realtime 'wins' feeds of the best plays. Integrate with our API.",
    },
  ];


  return (
    <div className=" pt-[20px] pb-[50px]  px-[20px] md:px-[0px] card-main">
      <div className="mx-auto px-[20px] max-w-[796px] text-[24px] md:text-[30px] md:text-[43px] font-extrabold leading-[35px] md:leading-[50px] fontClass2 text-white text-center">
        Automatically sell your tokens.<br/>
        Keep your gains.
      </div>
      <div className="mt-[20px] flex justify-around items-center flex-wrap">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="background-container max-w-[1250px] mx-auto  justify-center h-[273px] items-start flex-col px-[]"
            >
              <div className="relative top-[15px] flex justify-center">
                <img
                  src={item.img}
                  alt={item.name}
                  className="w-[61px] h-[61px]"
                />
              </div>

              <div className="text-[22.73px] leading-[26.63px] text-white fontClass2 self-center relative top-[30px] text-center">
                {item.name}
              </div>

              <div className="text-[14.77px] leading-[19.43px] fontClass2 max-w-[328px] text-center text-[#D1D1D1] uppercase mt-[10px] self-center relative top-[40px]">
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardSection;
