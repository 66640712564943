import React from "react";

const Notification = () => {
  return (
    <div className="p-[10px]  max-w-[277px] bg-[#101320] ">
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <div className="py-[10px] border-b-[2px] border-b-[#565656] ">
            <div className="text-[12px] leading-[14px] font-normal text-[#C0C0C0] max-w-[232px] text-start ">
              Your Position of 15.06 $SLERF was automatically sold
            </div>
           <div className="mt-[10px] w-full flex   justify-end text-end">
            <img src={require("../assets/Dismiss.png")} alt="" />
           </div>
          </div>
        );
      })}
    </div>
  );
};

export default Notification;
