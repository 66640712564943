import logo from "./logo.svg";
import "./App.css";
import Home from "./Home/Home";
import Header from "./Header/Header";
import Wallet from "./Wallet/Wallet";
import Notification from "./Wallet/Notification";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={} /> */}

        <Route path="/" element={<Home />} />
        <Route path="/wallet" element={<Wallet />} />
        
      </Routes>
    </div>
  );
}

export default App;
