import React from "react";

const Footer2 = () => {
  return (
    <div className="card-main  py-[100px] px-[50px] flex   flex-col  md:flex-row  justify-center gap-[50px] md:gap-[0px]  md:justify-between items-center  ">
        <div>
          <img src={require("../assets/image 1 (1).png")} alt="" />
        </div>

        <div className="flex gap-[30px] justify-center items-center ">
          {/* <div>
          <img src={require("../assets/divider.png")} alt="" />
        </div> */}

          <div>
            <div className=" text-center text-white text-[20px] leading-[30px] font-bold fontCLass">
              Follow us on
            </div>
            <div className="mt-[10px]">
              <img src={require("../assets/Frame 1.png")} alt="" />
            </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Footer2;
