import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
const InputSection = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currencies = [
    {
      value: "USD",
      label: "5px",
    },
    {
      value: "EUR",
      label: "5px",
    },
    {
      value: "BTC",
      label: "฿",
    },
    {
      value: "JPY",
      label: "¥",
    },
  ];

  return (
    <div className="bg-cl  pt-[20px] pb-[50px]">
      <div className="flex justify-center text-white items-center gap-[50px]">
        <div className="flex gap-[10px] justify-center items-center ">
          <div>
            <img src={require("../assets/Vector.png")} alt="" />
          </div>
          <div className="text-[12px] leading-[18px] fontClass2 text-white">
            13213123123
          </div>
        </div>
        <div className="flex gap-[20px]">
          <div className="background-header1 p-[10px]  flex justify-center items-center   text-[#FE509E] text-[8px] leading-[12px] fontClass2 ">
            HALF
          </div>
          <div className="background-header1 text-[#FE509E] text-[8px] flex justify-center items-center leading-[12px] fontClass2 ">
            MAX
          </div>
        </div>
      </div>


      <div className=" mt-[30px] flex  flex-col md:flex-row   justify-center md:justify-between items-center px-[50px]">
        <div className="mt-[20px]">
          <TextField
            id="outlined-select-currency"
            select
            label="Amount Purchasing"
            defaultValue="EUR"
            className="w-[242px] h-[63px]"
            InputProps={{
              style: {
                color: "WHITE",
                background: "black",
                margin: "5px",
              },
              sx: { "& fieldset": { borderColor: "#FE8077" } },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={require("../assets/Group 18.png")} alt="" />{" "}
                  {/* Custom icon */}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: "#FE509E" }, // Change color of the label
            }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="mt-[20px]">
          <TextField
            id="outlined-select-currency"
            select
            label="Priority Fees"
            defaultValue="EUR"
            className="w-[242px] h-[63px]"
            InputProps={{
              style: {
                color: "WHITE",
                background: "black",
                margin: "5px",
              },
              sx: { "& fieldset": { borderColor: "#FE8077" } },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={require("../assets/Group 18.png")} alt="" />{" "}
                  {/* Custom icon */}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: "#FE509E" }, // Change color of the label
            }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="   md:mt-[50px] flex flex-col md:flex-row   justify-center md:justify-between  items-center px-[50px]">
        <div className="mt-[20px]">
          <TextField
            id="outlined-select-currency"
            select
            label="Trigger Percent"
            defaultValue="EUR"
            className="w-[242px] h-[63px]"
            InputProps={{
              style: {
                color: "WHITE",
                background: "black",
                margin: "5px",
              },
              sx: { "& fieldset": { borderColor: "#FE8077" } },
              endAdornment: (
                <InputAdornment position="end">
                  <img src={require("../assets/Group 18.png")} alt="" />{" "}
                  {/* Custom icon */}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: "#FE509E" }, // Change color of the label
            }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="mt-[20px]">
          <TextField
            id="outlined-select-currency"
            select
            label="Slippage"
            defaultValue="EUR"
            className="w-[242px] h-[63px] border-[1px]  pr-[20px]  border-white"
            InputProps={{
              style: {
                color: "WHITE",
                background: "black",

                margin: "5px",
              },
              sx: { "& fieldset": { borderColor: "#FE8077" } },

              endAdornment: (
                <InputAdornment position="end">
                  <img src={require("../assets/Group 18.png")} alt="" />{" "}
                  {/* Custom icon */}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: "#FE509E" }, // Change color of the label
            }}
            SelectProps={{
              IconComponent: () => null, // Hide the default icon
            }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="flex  pl-[50px]  mt-[20px] gap-[20px]">
        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
        <label
          for="vehicle1"
          className="text-[white] text-[12px] leading-[18px] fontClass2"
        >
          {" "}
          Auto Double Slippage
        </label>
      </div>

      <div className="flex justify-between items-center mt-[50px] px-[10px] sm:px-[50px]">
        <div className="font-semibold text-[16px] leading-[24px] fontClass2 videClla">
          Your’e Buying
        </div>
        <div className="text-[12px] leading-[18px0] font-normal  fontClass2 text-[#CBCACA] ">
          USD
        </div>
      </div>
      <div className="flex max-w-[600px]  px-[10px] sm:px-[0px] h-[81px] mx-auto p-[10px] mt-[10px]  text-bg  justify-between items-center">
        <div className="flex gap-[10px]   border-[1px] border-[#FE509E] rounded-[13.59px] bg-[black] p-[10px]">
          <div>
            <img src={require("../assets/Rectangle 22.png")} alt="" />
          </div>
          <select
            id="selector"
            className="custom-select text-[22.5px]  leading-[33px] fontClass font-bold text-white  bg-[black] border-none outline-none"
          >
            <option value="">WJF</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
        </div>
        <div>
          <div className="  text-[14px] sm:text-[20.39px] leading-[30px] fontClass2 text-white font-medium">
            14.2412323234
          </div>
          <div className=" text-[10px] sm:text-[12px] text-end leading-[18px] fontClass2 text-[#CBCACA] font-medium">
            $14.2412323234
          </div>
        </div>
      </div>

      <div className="mt-[20px] text-[16px]  leading-[24px]  pl-[10px] sm:pl-[50px] font-normal  fontClass2 text-start text-white">
        Platform fee: <span className="videClla font-semibold"> 1%</span>
      </div>

      <div className="flex  text-[#D4D4D4]   h-[81px]  text-[12px] leading-[21px] text-start fontClass2 max-w-[600px] mx-auto p-[10px] mt-[10px]  text-bg  justify-between items-center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel
        consequat risus. Quisque at libero i
      </div>

      <div
        onClick={handleShow}
        className=" mt-[20px] colorClass max-w-[188px] mx-auto px-[30px] py-[10px] text-white text-[17px] leading-[17px] text-center font-bold fontClass rounded-[8px]"
      >
        Place Order
      </div>
    </div>
  );
};

export default InputSection;
