import React from "react";

const Footer = () => {
    return (
        <div className="card-main py-[20px] px-[50px] flex justify-center items-center">
            <div className="text-center">
                <div className="text-white text-[20px] leading-[30px] font-bold fontClass">
                    Follow us on
                </div>
                <div className="mt-[10px] flex justify-center items-center gap-[20px]">
                    <a
                        href="https://x.com/solsentrybot"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={require("../assets/Twitter.png")}
                            alt="Twitter"
                        />
                    </a>
                    <a
                        href="https://t.me/solsentrybetausers"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={require("../assets/telegram-icon.png")}
                            alt="Facebook"
                        />
                    </a>
                    <a
                        href="https://discord.gg/kVThq2nF4P"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={require("../assets/discord-icon.png")}
                            alt="Instagram"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
