import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="flex  pt-[20px]  px-[10px] sm:px-[50px] justify-between items-center">
      <Link to="/">
        <div>
          <img src={require("../assets/image 1.png")} alt="" />
        </div>
      </Link>



    </div>
  );
};

export default Header;
